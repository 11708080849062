import React, { Component } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import IconButton from '@material-ui/core/IconButton'
import ArrowLeft from '@material-ui/icons/NavigateBefore'
import ArrowRight from '@material-ui/icons/NavigateNext'
import Close from '@material-ui/icons/Close'
import paper from '@material-ui/core/Paper'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import queryString from 'query-string'
import Theme from '../utils/theme'
import TextField from '@material-ui/core/TextField'
import _flatten from 'lodash/flatten'
import _transform from 'lodash/transform'
import _union from 'lodash/union'
import _difference from 'lodash/difference'
import _intersection from 'lodash/intersection'
import _pullAll from 'lodash/pullAll'
import {
  initialValue,
} from '../utils/staticContent'
import {
  defaultSynonyms,
  defaultRelatedWords,
  defaultTemplateState,
  secondSentenceState,
  workHardPlayHardSentenceState,
  hobbySentenceState,
  popSocketSentenceState,
  popSocket2SentenceState,
  christmasSentenceState,
} from '../templateHelpers'
import { Value, Change, Inline, Data } from 'slate'
import { getEventRange } from 'slate-react'
import Plain from 'slate-plain-serializer'
import { example } from '../utils/exampleResponse'
import { saveSentence } from '../fireHelpers/user'
import SentenceEditor from '../components/SentenceEditor'
import OptionsList from '../components/OptionsList'
import {
  StepNumber,
  KeyWordHeader,
} from './KeywordHeader'
import { CopyButton, SaveButton, SaveButtons } from './Buttons'

const API_URL = process.env.API_URL

const initialJSONValue = () => Value.fromJSON(initialValue)

const BuilderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
`

const LeftSection = styled.section`
  padding-right: 1em;
  display: flex;
  flex-direction: row;
  border-right: 3px solid black;
  min-width: 400px;
  @media (max-width: 1000px) {
    border-right: 0px;
    grid-column: span 2;
  }
`

const RightSection = styled.section`
  @media (max-width: 1000px) {
    grid-column: span 2;
  }
`

const SentenceBlock = styled.div`
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  min-width: 300px;
`

const TemplateSection = styled.section`
  // width: 600px;
`

const SentenceLabelAndEditor = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const SentenceLabel = styled.h4`
  color: lightgrey;
  font-size: 3rem;
  margin: 1rem;
  align-self: center;
`

const Paper = styled(paper)`
  margin-left: 1.1rem;
  width: 100%;
`

const SentenceHeaderSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-left: 5rem;
`

const TemplateTogglerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  width: 200px;
  margin-bottom: 1rem;
  font-weight: bold;
  line-height: 15px;
`

const TemplateName = styled.h3`
  font-weight: bold;
`
const ModalPaper = styled(Paper)`
  margin: auto;
  margin-top: 10%;
  max-width: 550px;
  background-color: white !important;
  transition: height 2s;
`

const TemplateToggler = ({ onClick }) => {
  return (
    <TemplateTogglerWrapper>
      <IconButton color="secondary" size="small" onClick={() => onClick(-1)}>
        <ArrowLeft />
      </IconButton>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontStyle: 'bold',
          justifyContent: 'center',
        }}
      >
        <div>Next</div>
        <div>Template</div>
      </div>
      <IconButton color="secondary" size="small" onClick={() => onClick(1)}>
        <ArrowRight />
      </IconButton>
    </TemplateTogglerWrapper>
  )
}

const newInline = (option, key, type) => ({
  object: 'inline',
  type: type,
  data: { ukey: key },
  nodes: [
    {
      object: 'text',
      leaves: [
        {
          text: option,
        },
      ],
    },
  ],
})

const defaultState = {
  selectedWords: {},
  selectedTemplate1: 1,
  selectedTemplate2: 1,
  bulletValue1TemplateName: defaultTemplateState().templateName,
  bulletValue2TemplateName: defaultTemplateState().templateName,
  bulletValue1: initialJSONValue(),
  bulletValue2: initialJSONValue(),
  bulletValue1Keys: defaultTemplateState().keys,
  bulletValue2Keys: defaultTemplateState().keys,
  keys: _union(defaultTemplateState().keys, defaultTemplateState().keys),
  types: _union(defaultTemplateState().types, defaultTemplateState().types),
  synonymKeysAvail: _union(
    defaultTemplateState().synonymKeysAvail,
    defaultTemplateState().synonymKeysAvail
  ),
  relatedWordKeysAvail: _union(
    defaultTemplateState().relatedWordKeysAvail,
    defaultTemplateState().relatedWordKeysAvail
  ),
  nextsynonymKey: Math.max(
    defaultTemplateState().nextsynonymKey,
    defaultTemplateState().nextsynonymKey
  ),
  nextrelatedWordKey: Math.max(
    defaultTemplateState().nextrelatedWordKey,
    defaultTemplateState().nextrelatedWordKey
  ),
  inputVal: '',
  displayName: '',
  sentenceId: null,
  fetching: false,
}

class BulletBuilder extends Component {
  constructor(props) {
    super(props)

    this.state = {
      uid: 1234,
      modalOpen: false,
      synonyms: defaultSynonyms,
      relatedWords: defaultRelatedWords,
      templatesAvailable: ['template1', 'template2'],
      templates: {
        template1: defaultTemplateState(),
        template2: secondSentenceState(),
        template3: workHardPlayHardSentenceState(),
        template4: hobbySentenceState(),
        template5: popSocketSentenceState(),
        template6: popSocket2SentenceState(),
        template7: christmasSentenceState(),
      },
      ...defaultState,
    }
    this.onChange = ({ value, valueName = 'bulletValue1' }) => {
      console.log('onChange:')
      return this.setState({ [valueName]: value })
    }
    this.onCopyChange = ({ value, valueName = 'bulletValue1' }) =>
      this.setState({ [valueName]: value }, () => {
        console.log('COPYING!')
        document.execCommand('copy') // Copied to clipboard
      })
  }

  componentDidMount() {
    const parsedSearch = queryString.parse(this.props.location.search)
    if (parsedSearch.bulletId) {
      this.onSwitchSelectedSentence({ sentenceId: parsedSearch.bulletId })
    }
  }

  handleModalClose = () => {
    this.setState({ modalOpen: false })
  }

  handleModalOpen = () => {
    this.setState({ modalOpen: true })
  }
  fetchWords = () => {
    this.setState({ fetching: true })
    axios
      .post(`${API_URL}/websterentry`, { entry: this.state.inputVal })
      .then(results => {
        console.log('results:', results)
        const _synonyms = {}
        const _relatedWords = {}
        results.data.synonyms.reduce((synonyms, synonym) => {
          synonyms[synonym.word] = synonym
          return synonyms
        }, _synonyms)
        results.data.relatedWords.reduce((relatedWords, relatedWord) => {
          relatedWords[relatedWord.word] = relatedWord
          return relatedWords
        }, _relatedWords)
        const wordMaps = _transform(
          this.state.selectedWords,
          (result, value, key) => {
            if (value.type === 'synonym' && !value.default) {
              result.synonyms[value.word] = value
            } else if (value.type === 'relatedWord' && !value.default) {
              result.relatedWords[value.word] = value
            }
          },
          { synonyms: {}, relatedWords: {} }
        )

        const newSynonyms = Object.assign({}, _synonyms, wordMaps.synonyms)
        const newRelatedWords = Object.assign(
          {},
          _relatedWords,
          wordMaps.relatedWords
        )

        this.setState({
          synonyms: newSynonyms,
          relatedWords: newRelatedWords,
          fetching: false,
        })
      })
      .catch(error => {
        console.log('error:', error)
        this.setState({ fetching: false, errorFetching: true })
      })
  }

  switchTemplate = (direction, bulletValueId) => {
    const selectedTemplate =
      bulletValueId === 'bulletValue2'
        ? 'selectedTemplate2'
        : 'selectedTemplate1'
    let nextSelectedTemplateNumber = this.state[selectedTemplate] + direction
    if (nextSelectedTemplateNumber > 7) nextSelectedTemplateNumber = 1
    if (nextSelectedTemplateNumber < 1) nextSelectedTemplateNumber = 7
    this.applyNewTemplate({
      templateId: `template${nextSelectedTemplateNumber}`,
      bulletValueId,
    })
    this.setState(() => ({ [selectedTemplate]: nextSelectedTemplateNumber }))
  }

  onInputChange = e => {
    this.setState({ inputVal: e.target.value })
  }

  onDisplayNameChange = e => {
    this.setState({ displayName: e.target.value })
  }

  onSaveSentence = ({ isUpdate } = {}) => {
    const sentenceContent1 = JSON.stringify(this.state.bulletValue1.toJSON())
    const sentenceContent2 = JSON.stringify(this.state.bulletValue2.toJSON())
    const plainTextContent1 = Plain.serialize(this.state.bulletValue1)
    const plainTextContent2 = Plain.serialize(this.state.bulletValue2)
    const sentenceKeys1 = this.state.bulletValue1Keys
    const sentenceKeys2 = this.state.bulletValue2Keys
    const selectedWords = this.state.selectedWords
    const keys = this.state.keys
    const types = this.state.types
    const synonymKeysAvail = this.state.synonymKeysAvail
    const nextsynonymKey = this.state.nextsynonymKey
    const relatedWordKeysAvail = this.state.relatedWordKeysAvail
    const nextrelatedWordKey = this.state.nextrelatedWordKey
    const sentenceTemplateName1 = this.state.bulletValue1TemplateName
    const sentenceTemplateName2 = this.state.bulletValue2TemplateName
    const displayName = this.state.displayName
    const sentenceId = isUpdate ? this.state.sentenceId : null // if it's NOT an update (the desire is to create something new) then it doesn't matter what the sentneceId is

    const data = {
      sentenceContent1,
      sentenceContent2,
      plainTextContent1,
      plainTextContent2,
      sentenceKeys1,
      sentenceKeys2,
      selectedWords,
      nextsynonymKey,
      nextrelatedWordKey,
      synonymKeysAvail,
      relatedWordKeysAvail,
      keys,
      types,
      sentenceTemplateName1,
      sentenceTemplateName2,
      displayName,
    }
    if (this.props.userData.accountType === 'PRO')
      saveSentence(this.props.user, data, sentenceId)
    this.setState({ modalOpen: false })
  }

  replaceNodeChange = (curStateValue, key, inlineJson) => {
    let change = curStateValue.change()

    let nodeToReplace = curStateValue.document.findDescendant(
      block => block.data && block.data.get('ukey') === key
    )
    return change.replaceNodeByKey(
      nodeToReplace.key,
      Inline.fromJSON(inlineJson)
    )
  }

  onClickInsertPlaceHolder = (e, bulletValueId, type = 'synonym') => {
    e.preventDefault()
    const change = this.state[bulletValueId].change()
    this.insertPlaceHolder(change, bulletValueId, type)
  }

  insertPlaceHolder = (change, bulletValueId, type) => {
    const nextType = `${type}${this.state.nextsynonymKey}`

    change.insertInline({
      object: 'inline',
      type: 'synonym',
      data: { ukey: nextType },
      nodes: [
        {
          object: 'text',
          leaves: [
            {
              text: nextType,
            },
          ],
        },
      ],
    })

    this.onChange({ value: change.value, valueName: bulletValueId })
    this.setState({
      [`${type}KeysAvail`]: _union(this.state[`${type}KeysAvail`], [nextType]),
      [`${bulletValueId}Keys`]: _union(this.state[`${bulletValueId}Keys`], [
        nextType,
      ]),
      [`next${type}Key`]: this.state[`next${type}Key`] + 1,
    })
  }

  onKeywordClick = wordObject => {
    const { word, key, type } = wordObject
    const { selectedWords } = this.state
    const curSelectedWord = selectedWords[key]
    const wordList = this.state[`${type}s`]
    if (curSelectedWord && !curSelectedWord.default) {
      // if the word is already selected - unselect it
      const newSelectedWord = Object.assign({}, curSelectedWord, {
        word: curSelectedWord.key,
        selected: false,
        default: true,
      })
      const newSelectedState = Object.assign({}, selectedWords, {
        [`${newSelectedWord.key}`]: newSelectedWord,
      })
      // updates the map of possible words
      const newListWord = Object.assign({}, curSelectedWord, {
        key: null,
        selected: false,
      })
      const newListWords = Object.assign({}, wordList, {
        [newListWord.word]: newListWord,
      })
      // return key to KeysAvail
      const updatedKeysAvail = this.state[`${type}KeysAvail`].slice() // copy state;
      updatedKeysAvail.push(curSelectedWord.key)
      this.setState({
        selectedWords: newSelectedState,
        [`${type}s`]: newListWords,
        [`${type}KeysAvail`]: updatedKeysAvail,
      })

      //--bulletValue1
      if (this.state.bulletValue1Keys.includes(newSelectedWord.key)) {
        let inlineJson = newInline(
          newSelectedWord.word,
          newSelectedWord.key,
          newSelectedWord.type
        )
        console.log('newSelectedWord:', newSelectedWord)
        let change = this.replaceNodeChange(
          this.state.bulletValue1,
          newSelectedWord.key,
          inlineJson
        )
        this.onChange({ value: change.value, valueName: 'bulletValue1' })
      }

      //--bulletValue2
      if (this.state.bulletValue2Keys.includes(newSelectedWord.key)) {
        let inlineJson = newInline(
          newSelectedWord.word,
          newSelectedWord.key,
          newSelectedWord.type
        )
        console.log('newSelectedWord:', newSelectedWord)
        let change = this.replaceNodeChange(
          this.state.bulletValue2,
          newSelectedWord.key,
          inlineJson
        )
        this.onChange({ value: change.value, valueName: 'bulletValue2' })
      }
    } else {
      // only allow another to be selected if there's an available key
      if (this.state[`${type}KeysAvail`].length) {
        // if the word isn't selected - select it
        const updatedKeysAvail = this.state[`${type}KeysAvail`].slice() // copy state;
        const selectedKey = updatedKeysAvail.sort().shift() // take a key from those available

        const newSelectedWord = Object.assign({}, wordObject, {
          word: wordObject.word,
          selected: false,
          key: selectedKey,
          default: false,
        })
        // const newSelectedWord = Object.assign({}, wordObject, { word: wordObject.word, selected: true, key: selectedKey });
        const newSelectedState = Object.assign({}, selectedWords, {
          [newSelectedWord.key]: newSelectedWord,
        })
        // updates the map of possible words
        const newListWord = Object.assign({}, newSelectedWord)
        const newListWords = Object.assign({}, wordList, {
          [newListWord.word]: newListWord,
        })
        this.setState({
          selectedWords: newSelectedState,
          [`${type}s`]: newListWords,
          [`${type}KeysAvail`]: updatedKeysAvail,
        })

        //--bulletValue1
        if (this.state.bulletValue1Keys.includes(newSelectedWord.key)) {
          let inlineJson = newInline(
            newSelectedWord.word,
            newSelectedWord.key,
            newSelectedWord.type
          )
          console.log('newSelectedWord:', newSelectedWord)
          let change = this.replaceNodeChange(
            this.state.bulletValue1,
            newSelectedWord.key,
            inlineJson
          )
          this.onChange({ value: change.value, valueName: 'bulletValue1' })
        }

        //--bulletValue2
        if (this.state.bulletValue2Keys.includes(newSelectedWord.key)) {
          let inlineJson = newInline(
            newSelectedWord.word,
            newSelectedWord.key,
            newSelectedWord.type
          )
          console.log('newSelectedWord:', newSelectedWord)
          let change = this.replaceNodeChange(
            this.state.bulletValue2,
            newSelectedWord.key,
            inlineJson
          )
          this.onChange({ value: change.value, valueName: 'bulletValue2' })
        }
      }
    }
  }

  onSwitchSelectedSentence = ({ sentenceId }) => {
    console.log('sentenceId:', this.props.sentences, sentenceId)
    const sentence = this.props.sentences[sentenceId]
    if (sentence) {
      // const val1 = Value.fromJSON(sentence.sentenceContent1);
      // const val2 = Value.fromJSON(sentence.sentenceContent2);
      const val1 = Value.fromJSON(sentence.bulletValue1)
      const val2 = Value.fromJSON(sentence.bulletValue2)
      console.log(
        'selectedWords in Saved Sentence Data:',
        sentence.selectedWords
      )
      const wordMaps = _transform(
        sentence.selectedWords,
        (result, value, key) => {
          if (value.type === 'synonym' && !value.default) {
            result.synonyms[value.word] = Object.assign({}, value)
          } else if (value.type === 'relatedWord' && !value.default) {
            result.relatedWords[value.word] = Object.assign({}, value)
          }
        },
        { synonyms: {}, relatedWords: {} }
      )

      const newSynonyms = Object.assign(
        {},
        this.state.synonyms,
        wordMaps.synonyms
      )
      const newRelatedWords = Object.assign(
        {},
        this.state.relatedWords,
        wordMaps.relatedWords
      )
      console.log('newSynonyms:', newSynonyms)
      console.log('newRelatedWords:', newRelatedWords)

      this.onChange({ value: val1, valueName: 'bulletValue1' })
      this.onChange({ value: val2, valueName: 'bulletValue2' })
      this.setState({
        synonymKeysAvail: sentence.synonymKeysAvail,
        relatedWordKeysAvail: sentence.relatedWordKeysAvail,
        bulletValue1Keys: sentence.sentenceKeys1,
        bulletValue2Keys: sentence.sentenceKeys2,
        selectedWords: sentence.selectedWords,
        bulletValue1TemplateName: sentence.sentenceTemplateName1,
        bulletValue2TemplateName: sentence.sentenceTemplateName2,
        synonyms: newSynonyms,
        relatedWords: newRelatedWords,
        sentenceId: sentenceId,
        displayName: sentence.displayName,
      })
    }
  }

  applyNewTemplate = ({
    templateId = 'template1',
    bulletValueId = 'bulletValue1',
  }) => {
    let val = Value.fromJSON(this.state.templates[templateId].sentence)
    let templateKeys = this.state.templates[templateId].keys.slice()
    let templateSynonymKeys = this.state.templates[templateId].synonymKeysAvail
    let templateRelatedWordKeys = this.state.templates[templateId]
      .relatedKeysAvail
    let templateName = this.state.templates[templateId].templateName
    // console.log('templateName:', templateName);
    // console.log('templateStateName:', `${templateId}Name`);
    // console.log('template State:', this.state[`${templateId}Name`]);
    const keepKeys =
      bulletValueId === 'bulletValue1'
        ? this.state.bulletValue2Keys
        : this.state.bulletValue1Keys
    const removeKeys = this.state[`${bulletValueId}Keys`]
    const scrubKeys = _difference(removeKeys, keepKeys)
    let selectedWordKeys = Object.keys(this.state.selectedWords)
    const retainedSelectedWords = {}

    const nothing = selectedWordKeys.map(key => {
      if (!scrubKeys.includes(key))
        retainedSelectedWords[key] = Object.assign(
          {},
          this.state.selectedWords[key]
        )
    })
    selectedWordKeys = Object.keys(retainedSelectedWords) // grab an array of the selectedWord object keys
    let newSynonymKeysAvail = _pullAll(this.state.synonymKeysAvail, scrubKeys) // remove the keys to be scrubbed
    let newRelatedWordsKeysAvail = _pullAll(
      this.state.relatedWordKeysAvail,
      scrubKeys
    ) // remove the keys to be scrubbed
    // ************************************************************************
    // ******** NOW WE NEED TO ADD IN ANY NEW KEYS TO THOSE AVAILALBE!!!
    // ************************************************************************
    selectedWordKeys = _pullAll(selectedWordKeys, scrubKeys)
    newSynonymKeysAvail = _union(
      newSynonymKeysAvail,
      _difference(templateSynonymKeys, selectedWordKeys, newSynonymKeysAvail)
    )
    newRelatedWordsKeysAvail = _union(
      newRelatedWordsKeysAvail,
      _difference(
        templateRelatedWordKeys,
        selectedWordKeys,
        newRelatedWordsKeysAvail
      )
    )
    console.log('selectedWordKeys:', selectedWordKeys)
    console.log('templateId:', templateId)
    console.log('bulletValueId:', selectedWordKeys)

    // apply any existing selectedWords as changes to the new template sentence
    for (let i = 0; i < selectedWordKeys.length; i += 1) {
      let newSelectedWord = this.state.selectedWords[selectedWordKeys[i]]
      if (templateKeys.includes(newSelectedWord.key)) {
        let inlineJson = newInline(
          newSelectedWord.word,
          newSelectedWord.key,
          newSelectedWord.type
        )
        val = this.replaceNodeChange(val, newSelectedWord.key, inlineJson).value
      }
    }
    let oppositeBulletValue

    if (bulletValueId)
      // apply all the new changes to state
      this.onChange({ value: val, valueName: bulletValueId })
    this.setState({
      synonymKeysAvail: newSynonymKeysAvail,
      relatedWordKeysAvail: newRelatedWordsKeysAvail,
      [`${bulletValueId}Keys`]: templateKeys.slice(),
      selectedWords: retainedSelectedWords,
      [`${bulletValueId}TemplateName`]: templateName,
      nextsynonymKey: Math.max(
        this.state.templates[templateId].nextsynonymKey,
        defaultTemplateState().nextsynonymKey
      ),
      nextrelatedWordKey: Math.max(
        this.state.templates[templateId].nextrelatedWordKey,
        defaultTemplateState().nextrelatedWordKey
      ),
    })
  }

  renderSentences = () => {
    const {
      selectedTemplate,
      templatesAvailable,
      templates,
      bulletValue1,
      bulletValue2,
      bulletValue1TemplateName,
      bulletValue2TemplateName,
    } = this.state
    return (
      <TemplateSection>
        <SentenceBlock key={`template${selectedTemplate}`}>
          <SentenceHeaderSection>
            <TemplateName>{bulletValue1TemplateName}</TemplateName>
            <TemplateToggler
              onClick={direction =>
                this.switchTemplate(direction, 'bulletValue1')
              }
            />
          </SentenceHeaderSection>
          <SentenceLabelAndEditor>
            <SentenceLabel>A</SentenceLabel>
            <Paper>
              <SentenceEditor
                onChange={({ value }) =>
                  this.onChange({ value, valueName: 'bulletValue1' })
                }
                value={bulletValue1}
              />
            </Paper>
          </SentenceLabelAndEditor>
          <CopyButton
            size="small"
            color="primary"
            variant="contained"
            onClick={() => this.copyInputValue('bulletValue1')}
          >
            Copy
          </CopyButton>
          {/* <CopyButton
            size="small"
            color="primary"
            variant="contained"
            onClick={e => this.onClickInsertPlaceHolder(e, 'bulletValue1')}
          >
            INSERT SYNONYM
          </CopyButton> */}
        </SentenceBlock>
        <SentenceBlock key={`template${selectedTemplate}2`}>
          <SentenceHeaderSection>
            <TemplateName>{bulletValue2TemplateName}</TemplateName>
            <TemplateToggler
              onClick={direction =>
                this.switchTemplate(direction, 'bulletValue2')
              }
            />
          </SentenceHeaderSection>
          <SentenceLabelAndEditor>
            <SentenceLabel>B</SentenceLabel>
            <Paper>
              <SentenceEditor
                onChange={({ value }) =>
                  this.onChange({ value, valueName: 'bulletValue2' })
                }
                value={bulletValue2}
              />
            </Paper>
          </SentenceLabelAndEditor>
          <CopyButton
            size="small"
            color="primary"
            variant="contained"
            onClick={() => this.copyInputValue('bulletValue2')}
          >
            Copy
          </CopyButton>
          {/* <CopyButton
            size="small"
            color="primary"
            variant="contained"
            onClick={e => this.onClickInsertPlaceHolder(e, 'bulletValue2')}
          >
            INSERT SYNONYM
          </CopyButton> */}
        </SentenceBlock>
      </TemplateSection>
    )
  }
  copyInputValue = valueName => {
    const change = this.state[valueName]
      .change()
      .focus()
      .selectAll() // selecting value
    this.onCopyChange({ value: change.value, valueName: valueName })
  }

  render() {
    const {
      noun1,
      noun2,
      verb1,
      selectedTemplate,
      synonyms,
      relatedWords,
      inputVal,
      synonymKeysAvail,
      relatedWordKeysAvail,
      selectedWords,
      displayName,
      fetching,
    } = this.state
    return (
      <MuiThemeProvider theme={Theme}>
        <div>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.modalOpen}
            onClose={this.handleModalClose}
          >
            <ModalPaper>
              <div
                style={{
                  alignSelf: 'center',
                  textAlign: 'center',
                  backgroundColor: 'black',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '0.5rem 1rem 0.5rem 2rem',
                  justifyContent: 'space-between',
                }}
              >
                <h3
                  style={{
                    color: 'white',
                    fontSize: '2.5rem',
                    marginTop: '1.5rem',
                  }}
                >
                  {'Save Bullets'}
                </h3>
                <IconButton color="secondary" onClick={this.handleModalClose}>
                  <Close style={{ fontSize: 32 }} />
                </IconButton>
              </div>
              <div style={{ padding: '1rem 3rem' }}>
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    this.onSaveSentence()
                  }}
                >
                  <TextField
                    onSubmit={e => {
                      e.preventDefault()
                      this.onSaveSentence()
                    }}
                    autoFocus
                    fullWidth
                    helperText={
                      'Give this set of bullets a name, i.e. Generic Hiking'
                    }
                    value={displayName}
                    onChange={this.onDisplayNameChange}
                  />
                  <CopyButton
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={() => this.onSaveSentence()}
                  >
                    OK
                  </CopyButton>
                </form>
              </div>
            </ModalPaper>
          </Modal>
          {/* <Button onClick={() => this.onSwitchSelectedSentence({})}>SWITCH DEFAULT SENTENCE</Button> */}
          <BuilderWrapper>
            <LeftSection>
              <OptionsList
                fetching={fetching}
                fetchWords={this.fetchWords}
                onKeywordClick={this.onKeywordClick}
                synonyms={synonyms}
                selectedWords={selectedWords}
                synonymKeysAvail={synonymKeysAvail}
                relatedWordKeysAvail={relatedWordKeysAvail}
                relatedWords={relatedWords}
                inputVal={inputVal}
                onInputChange={this.onInputChange}
              />
            </LeftSection>
            <RightSection>
              <KeyWordHeader>
                {' '}
                <StepNumber num={3} /> SELECT A BULLET TEMPLATE
                <SaveButtons>
                  <SaveButton
                    size="small"
                    color="primary"
                    variant="contained"
                    disabled={this.props.userData.accountType !== 'PRO'}
                    onClick={this.handleModalOpen}
                  >
                    {this.state.sentenceId ? 'Save New' : 'Save'}
                  </SaveButton>
                  {this.state.sentenceId ? (
                    <SaveButton
                      size="small"
                      color="secondary"
                      variant="contained"
                      disabled={this.props.userData.accountType !== 'PRO'}
                      onClick={() => this.onSaveSentence({ isUpdate: true })}
                    >
                      Save
                    </SaveButton>
                  ) : null}
                </SaveButtons>
              </KeyWordHeader>
              <TextField
                fullWidth
                label={'Bullet Set Name'}
                value={displayName}
                onChange={this.onDisplayNameChange}
              />
              {this.renderSentences()}
            </RightSection>
          </BuilderWrapper>
        </div>
      </MuiThemeProvider>
    )
  }
}

export default BulletBuilder
