import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import BaseButton from '@material-ui/core/ButtonBase'
import { logout } from '../fireHelpers/auth'
import Theme from '../utils/theme'
import Logo100 from '../assets/Alpha-Merch-Badge-250.svg'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { FlexibleButton } from './Buttons'

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        style: {
          color: isCurrent ? 'red' : 'blue',
        },
      }
    }}
  />
)

const StyledProLink = styled(NavLink)`
  border: 2px solid white;
  border-radius: 40px;
  color: white;
  font-size: 1em;
  text-decoration: none;
  padding: 0.3em;
  &:hover {
    color: ${Theme.palette.secondary.main} !important;
  }
  &:visited {
    color: white;
  }
`

const StyledLink = styled(NavLink)`
  color: white !important;
  font-size: 1em;
  text-decoration: none;
  text-align: center;
  line-height: 2;
  font-weight: bold;
  margin: 0.25rem;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  height: 100%;
  &:hover {
    color: ${Theme.palette.secondary.main} !important;
  }
`

const AccountLink = StyledLink.extend`
  margin: 0;
  padding: 0;
  color: black !important;
`

const StyledButton = styled(BaseButton)`
  color: white !important;
  font-size: 1em;
  margin: 0.25rem !important;
  padding: 0.25em 0.5em !important;
  border-radius: 3px;
  &:hover {
    color: ${Theme.palette.secondary.main} !important;
  }
`

const Logo = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: -100px;
  @media (max-width: 1000px) {
    width: 40px;
    height: 40px;
    margin-bottom: 0;
  }
`

const BrandHeader = styled.h1`
  margin-top: -15px;
  margin-bottom: 0;
`

const BrandTagline = styled.p`
  margin-bottom: -10px;
  margin-top: -10px;
  letter-spacing: 0.5px;
  text-align: end;
`

const activeStyle = {
  backgroundColor: `red !important`,
}

const renderBulletBuilderLink = ({ authed }) =>
  authed ? (
    <StyledLink
      activeStyle={activeStyle}
      to="/app/bulletbuilder"
      color="primary"
    >
      BULLET BUILDER
    </StyledLink>
  ) : (
    <StyledLink activeStyle={activeStyle} to="/app/login" color="primary">
      BULLET BUILDER
    </StyledLink>
  )

const renderSavedBullets = ({ authed }) =>
  authed ? (
    <StyledLink activeStyle={activeStyle} to="/app/bullets" color="primary">
      SAVED BULLETS
    </StyledLink>
  ) : (
    <StyledLink activeStyle={activeStyle} to="/app/login" color="primary">
      SAVED BULLETS
    </StyledLink>
  )

const renderCatsplat = ({ authed }) =>
  authed ? (
    <StyledLink activeStyle={activeStyle} to="/app/catsplat" color="primary">
      CATSPLAT
    </StyledLink>
  ) : (
    <StyledLink activeStyle={activeStyle} to="/app/login" color="primary">
      CATSPLAT
    </StyledLink>
  )

const BetaColorBar = styled.div`
  background: darkgrey;
  background: repeating-linear-gradient(
    45deg,
    ${Theme.palette.primary.superLight},
    ${Theme.palette.primary.superLight} 10px,
    ${Theme.palette.primary.main} 10px,
    ${Theme.palette.primary.main} 20px
  );
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  color: white;
`
const BetaBar = () => {
  return (
    <BetaColorBar>
      <div>
        LupaMerch is currently in beta. Please help us with your feedback.
      </div>
      <a
        style={{ textDecoration: 'none' }}
        href="mailto:lupamerch@gmail.com?subject=LupaMerch Feedback"
      >
        <FlexibleButton color="secondary" variant="contained">
          Provide Feedback
        </FlexibleButton>
      </a>
    </BetaColorBar>
  )
}

class NewHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
    }
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { anchorEl } = this.state
    const { auth } = this.props
    const { userData } = auth
    // console.log('userData', userData)
    const open = Boolean(anchorEl)
    return (
      <div>
        <AppBar position="fixed">
          <BetaBar />
          <Toolbar
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Link
              to="/"
              style={{
                color: 'white',
                textDecoration: 'none',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Logo src={Logo100} />
              <div>
                <BrandHeader>{'LupaMerch'}</BrandHeader>
                <BrandTagline>{'RAISE AN EMPIRE'}</BrandTagline>
              </div>
            </Link>

            {isWidthUp('md', this.props.width) ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {userData.accountType !== 'PRO' && (
                  <StyledProLink to="/subscription">GO PRO</StyledProLink>
                )}
                {renderBulletBuilderLink(auth)}
                {renderSavedBullets(auth)}
                {renderCatsplat(auth)}
                {auth.authed ? (
                  <span>
                    {userData.displayName.substring(
                      0,
                      userData.displayName.indexOf('@')
                    )}
                    <IconButton
                      aria-owns={open ? 'menu-appbar' : null}
                      aria-haspopup="true"
                      onClick={this.handleMenu}
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                  </span>
                ) : (
                  <StyledLink to="/app/login" color="primary">
                    LOGIN
                  </StyledLink>
                )}
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={logout}>LOGOUT</MenuItem>
                  <MenuItem>
                    <AccountLink to="/app/account" color="primary">
                      ACCOUNT
                    </AccountLink>
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  aria-owns={open ? 'small-menu-appbar' : null}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="small0menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={logout}>LOGOUT</MenuItem>
                  <MenuItem>
                    <AccountLink to="/app/bulletbuilder" color="primary">
                      Bullet Builder
                    </AccountLink>
                  </MenuItem>
                  <MenuItem>
                    <AccountLink to="/app/bullets" color="primary">
                      Saved Bullets
                    </AccountLink>
                  </MenuItem>
                  <MenuItem>
                    <AccountLink to="/app/catsplat" color="primary">
                      Catsplat
                    </AccountLink>
                  </MenuItem>
                  <MenuItem>
                    <AccountLink to="/app/account" color="primary">
                      ACCOUNT
                    </AccountLink>
                  </MenuItem>
                  <MenuItem>
                    <AccountLink to="/subscription" color="secondary">
                      GO PRO
                    </AccountLink>
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

export default withWidth()(NewHeader)
