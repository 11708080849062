import React from 'react'
import PropTypes from 'prop-types'
import { Router, navigate } from '@reach/router'

// More info at https://reacttraining.com/react-router/web/example/auth-workflow
const PrivateRoute = ({ component: Component, authed, ...rest }) => {
  if (!authed && window.location.pathname !== '/app/login') {
    navigate('/app/login')
    return null
  }

  return (
    <Router>
      <Component {...rest} />
    </Router>
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

// More info at https://reacttraining.com/react-router/web/example/auth-workflow
export const PublicRoute = ({ component: Component, authed, ...rest }) => {
  if (authed && window.location.pathname == '/app/login') {
    navigate('/app/bulletbuilder')
    return null
  }

  return (
    <Router>
      <Component {...rest} />
    </Router>
  )
}

export default PrivateRoute
