import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 750,
      md: 1000,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      superLight: '#7A7A7A',
      light: '#484848',
      main: '#212121',
      dark: '#000000',
      visited: 'grey',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ffca47',
      main: '#ff9900',
      dark: '#c66a00',
      contrastText: '#000000',
    },
    highlight: {
      main: '#700DFF',
      hover: '#E30CE8',
      error: '#FF0000',
      warn: '#E84F0C',
    },
    white: {
      superLight: '#7A7A7A',
      light: '#484848',
      main: '#ffffff',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
})

export default theme
