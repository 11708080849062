import fb from '../config/constants'
import moment from 'moment'

export function getUser(userId) {
  return fb
    .db()
    .ref(`users/${userId}/info`)
    .once('value')
    .then(snapshot => snapshot.val())
}

export function subscribeToRef(ref, callBack) {
  return ref.on('value', snapshot => {
    return callBack(snapshot.val())
  })
}

export function userRef(userId) {
  return fb.db().ref(`users/${userId}`)
}

export function getAllUserData(userId) {
  return fb
    .db()
    .ref(`users/${userId}`)
    .once('value')
    .then(snapshot => snapshot.val())
}

function setAvatar(userId, avatarUrl) {
  const updates = {}
  updates[`users/${userId}/info/avatarUrl`] = avatarUrl
  return fb
    .db()
    .ref()
    .update(updates)
}

export function uploadAvatar(userId, file) {
  const avatarRef = fb.storageRef.child(userId)
  return avatarRef.put(file).then(snapshot => {
    setAvatar(userId, snapshot.a.downloadURLs[0])
    return snapshot.a.downloadURLs[0]
  })
}

export function unSubscribe(ref) {
  return ref.off()
}

// saveSentence(this.props.user, sentenceContent, sentenceSelectedWords, JSON.stringify(metaVariables));
export function saveSentence(user, data, existingId) {
  if (existingId) {
    return fb.db
      .collection('users')
      .doc(user.uid)
      .collection('sentences')
      .doc(existingId)
      .set({
        ...data,
        creationDate: moment.now(),
      })
      .then(() => {
        console.log('Sucessfully Saved!')
      })
      .catch(error => console.error('Error writing document:', error))
  }
  return fb.db
    .collection('users')
    .doc(user.uid)
    .collection('sentences')
    .add({
      ...data,
      creationDate: moment.now(),
    })
    .then(() => {
      console.log('Sucessfully Saved!')
    })
    .catch(error => console.error('Error writing document:', error))
}

// shape of loadSentences response will be
// response = {
//   <id> : {
//     sentence: {Object},
//     selectedWords: {Object},
//     metaVariables: {
//       nextsynonymKey: Number,
//       nextrelatedWordKey: Number,
//       synonymKeysAvail: [Array],
//       relatedWordKeysAvail: [Array],
//       keys: [Array],
//       types: [Array],
//     }
//     creationDate:
//   }
// }
function jsonize({
  sentenceContent1,
  sentenceContent2,
  creationDate,
  ...rest
}) {
  const response = {
    bulletValue1: JSON.parse(sentenceContent1),
    bulletValue2: JSON.parse(sentenceContent2),
    creationDate: moment(creationDate),
    ...rest,
  }
  return response
}
export function loadSentences(user) {
  return fb.db
    .collection('users')
    .doc(user.uid)
    .collection('sentences')
    .get()
    .then(function(querySnapshot) {
      const response = {}
      querySnapshot.forEach(function(doc) {
        // doc.data() is never undefined for query doc snapshots
        response[doc.id] = jsonize(doc.data())
      })
      return response
    })
}

export function watchUser(user, callBack) {
  return fb.db
    .collection('users')
    .doc(user.uid)
    .onSnapshot(doc => {
      // console.log('User Snapshot updated')
      // console.log('doc.data()', doc.data())
      return callBack(doc.data())
    })
}

export function watchSentences(user, callBack) {
  return fb.db
    .collection('users')
    .doc(user.uid)
    .collection('sentences')
    .onSnapshot({ includeDocumentMetadataChanges: true }, snapshot => {
      let sentences = {}
      var source = snapshot.metadata.fromCache ? 'local cache' : 'server'
      // console.log('Data came from ' + source)
      snapshot.forEach(doc => {
        sentences[doc.id] = jsonize(doc.data())
      })
      return callBack(sentences)
    })
}

export function watchFileData(user, callBack) {
  return fb.db
    .collection('users')
    .doc(user.uid)
    .collection('files')
    .onSnapshot({ includeDocumentMetadataChanges: true }, snapshot => {
      let files = {}
      // console.log('snapshot')
      var source = snapshot.metadata.fromCache ? 'local cache' : 'server'
      // console.log('Data for files came from ' + source)
      snapshot.forEach(doc => {
        files[doc.id] = doc.data()
      })
      return callBack(files)
    })
}

// need algolio to do this
// export function searchSentences(user, queryString, callBack) {
//   return fb.db
//     .collection('users')
//     .doc(user.uid)
//     .collection('sentences')
//     .where("displayName", )
//     .onSnapshot({ includeDocumentMetadataChanges: true }, snapshot => {
//       let sentences = {}
//       var source = snapshot.metadata.fromCache ? 'local cache' : 'server'
//       console.log('Data came from ' + source)
//       snapshot.forEach(doc => {
//         sentences[doc.id] = jsonize(doc.data())
//       })
//       return callBack(sentences)
//     })
// }

export function updateUserName(userId, userName) {
  return fb.db
    .collection('users')
    .doc(userId)
    .update({
      displayName: userName,
    })
}

export function updateSubscriptionType(userId, subType) {
  return fb.db
    .collection('users')
    .doc(userId)
    .update({
      subscriptionType: subType,
    })
}

export function renewSubscriptionBeforeEndDate(userId) {
  const ref = fb.db.collection('users').doc(userId)
  return ref
    .get()
    .then(doc => {
      return ref.update({
        nextChargeDateTime: doc.data().subscriptionEnds,
        subscribed: true,
      })
    })
    .catch(err => {
      return err.message
    })
}

// fb.db.collection("cities").doc("SF")
//     .onSnapshot(function(doc) {
//         console.log("Current data: ", doc.data());
//     });

// export function saveUserData(userId, userInfo) {
//   // Get the current data in the fb.db for this user
//   return getUser(userId).then((data) => {
//     // Recursively merge the new data with the existing data
//     const newUserInfo = _.merge({}, data, userInfo);
//
//     // Write the new data
//     return fb.db().ref().child(`users/${userId}/info`).set(newUserInfo);
//   });
// }
