import React, { Component } from 'react'
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import Theme from '../utils/theme';

export const ActionButton = styled(Button)`
  margin: 0.25rem 1rem !important;
`;

export const FlexibleButton = ActionButton.extend`
  background-color: ${({ color }) => Theme.palette[color].main};
  a {
    text-decoration: none;
  }
`;

FlexibleButton.propTypes = {
  spacing: PropTypes.string,
  color: PropTypes.string.isRequired,
}

export const CopyButton = ActionButton.extend`
  margin: 0px !important;
  margin-top: 10px !important;
`;

export const SaveButton = ActionButton.extend`
  margin: 0px !important;
  margin-left: 4px !important;
  margin-top: 4px !important;
`;

export const SaveButtons = styled.div`
  display: flex;
  margin: 0px;
  margin-left: auto;
`;
