import React from 'react'
import BulletBuilder from '../components/BulletBuilder'
import Login from '../components/Login'
import PrivateRoute, { PublicRoute } from '../components/PrivateRoute'
import fb from '../config/constants'
import { loadSentences, watchUser, watchSentences } from '../fireHelpers/user'

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      authed: false,
      loading: true,
      user: {},
      sentences: {},
      sentenceIds: [],
      userData: {
        displayName: '',
        email: '',
        subSince: null,
        accountType: null,
        creationDate: null,
        subscriptionType: null,
        nextChargeDateTime: null,
        subscriptionEnds: null,
        subscribed: false,
      },
    }
    this.authOrSignOut = this.authOrSignOut.bind(this)
  }

  componentDidMount() {
    this.unregisterAuthObserver = fb
      .firebaseAuth()
      .onAuthStateChanged(authUser => {
        if (authUser) {
          this.unSubscribeUserData = watchUser(
            authUser,
            this.updateUserDataState
          )
          this.unSubscribeSentence = watchSentences(
            authUser,
            this.updateSentences
          )
          this.setState(() => ({
            user: authUser,
            authed: true,
            loading: false,
          }))
        } else {
          this.setState(() => ({ user: null, authed: false, loading: false }))
        }
      })
  }

  authOrSignOut(user) {
    if (user) {
      this.setState({
        authed: true,
        loading: false,
      })
    } else {
      this.setState({
        authed: false,
        loading: false,
      })
    }
  }

  updateUserDataState = newUserData => {
    this.setState({ userData: newUserData })
  }

  updateSentences = newSentences => {
    this.setState({
      sentences: newSentences,
      sentenceIds: Object.keys(newSentences),
    })
  }

  updateUserDataName = name => {
    this.setState({
      userData: Object.assign({}, this.state.userData, { displayName: name }),
    })
  }

  componentWillUnmount() {
    this.unregisterAuthObserver && this.unregisterAuthObserver()
    this.unSubscribeUserData && this.unSubscribeUserData()
    this.unSubscribeSentence && this.unSubscribeSentence()
  }

  render() {
    return this.props.children({
      ...this.props,
      ...this.state,
      updateUserDataName: this.updateUserDataName,
    })
  }
}
