import {
  initialValue,
  secondSentence,
  workHardPlayHard,
  hobby,
  christmas,
  popSocket,
  popSocket2,
} from '../utils/staticContent'

export const defaultSynonyms = {
  // Default: { word: 'Default', key: null, type: 'synonym' },
  // Synonyms: { word: 'Synonyms', key: null, type: 'synonym' },
  // King: { word: 'King', key: null, type: 'synonym' },
  // Sing: { word: 'Sing', key: null, type: 'synonym' },
}

export const defaultRelatedWords = {
  // Fishing: { word: 'Fishing', key: null, type: 'relatedWord' },
  // Squatching: { word: 'Squatching', key: null, type: 'relatedWord' },
  // Walking: { word: 'Walking', key: null, type: 'relatedWord' },
  // Hiking: { word: 'Hiking', key: null, type: 'relatedWord' },
}

export const defaultTemplateState = () => {
  const state = {
    templateName: 'SIMPLY GENERIC',
    keys: ['synonym1', 'synonym2', 'relatedWord1', 'relatedWord2'],
    types: ['synonym', 'relatedWord'],
    synonymKeysAvail: ['synonym1', 'synonym2'],
    nextsynonymKey: 3,
    relatedWordKeysAvail: ['relatedWord1', 'relatedWord2'],
    nextrelatedWordKey: 3,
    sentence: initialValue,
  }

  return Object.assign({}, state)
}

export const secondSentenceState = () => {
  const state = {
    templateName: '4TH QUARTER MADNESS',
    keys: ['synonym1', 'synonym2', 'synonym3'],
    types: ['synonym'],
    synonymKeysAvail: ['synonym1', 'synonym2', 'synonym3'],
    nextsynonymKey: 4,
    relatedWordKeysAvail: [],
    nextrelatedWordKey: 0,
    sentence: secondSentence,
  }

  return Object.assign({}, state)
}

export const workHardPlayHardSentenceState = () => {
  const state = {
    templateName: 'Work Hard Play Hard',
    keys: ['synonym1', 'synonym2', 'synonym3'],
    types: ['synonym'],
    synonymKeysAvail: ['synonym1', 'synonym2', 'synonym3'],
    nextsynonymKey: 4,
    relatedWordKeysAvail: [],
    nextrelatedWordKey: 0,
    sentence: workHardPlayHard,
  }

  return Object.assign({}, state)
}

export const hobbySentenceState = () => {
  const state = {
    templateName: 'Hobby',
    keys: ['synonym1', 'synonym2', 'synonym3'],
    types: ['synonym'],
    synonymKeysAvail: ['synonym1', 'synonym2', 'synonym3'],
    nextsynonymKey: 4,
    relatedWordKeysAvail: [],
    nextrelatedWordKey: 0,
    sentence: hobby,
  }

  return Object.assign({}, state)
}

export const popSocketSentenceState = () => {
  const state = {
    templateName: 'PopSocket 1',
    keys: ['synonym1', 'synonym2', 'synonym3'],
    types: ['synonym'],
    synonymKeysAvail: ['synonym1', 'synonym2', 'synonym3'],
    nextsynonymKey: 4,
    relatedWordKeysAvail: [],
    nextrelatedWordKey: 0,
    sentence: popSocket,
  }

  return Object.assign({}, state)
}

export const popSocket2SentenceState = () => {
  const state = {
    templateName: 'PopSocket 2',
    keys: ['synonym1', 'synonym2', 'synonym3'],
    types: ['synonym'],
    synonymKeysAvail: ['synonym1', 'synonym2', 'synonym3'],
    nextsynonymKey: 4,
    relatedWordKeysAvail: [],
    nextrelatedWordKey: 0,
    sentence: popSocket2,
  }

  return Object.assign({}, state)
}

export const christmasSentenceState = () => {
  const state = {
    templateName: 'Holiday',
    keys: ['synonym1', 'synonym2', 'synonym3'],
    types: ['synonym'],
    synonymKeysAvail: ['synonym1', 'synonym2', 'synonym3'],
    nextsynonymKey: 4,
    relatedWordKeysAvail: [],
    nextrelatedWordKey: 0,
    sentence: christmas,
  }

  return Object.assign({}, state)
}
