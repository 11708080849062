import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components';
import axios from 'axios';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import RBC from '@material-ui/icons/RadioButtonChecked';
import RBUC from '@material-ui/icons/RadioButtonUnchecked';
import Theme from '../../utils/theme';
import _pick from 'lodash/pick';
import Guid from '../../utils/Guid';

const ListWrapper = styled.div`
  padding: 0 1em;
  width: 45%;
  overflow-y: auto;
`;

const OptionsWrapper = styled.div`
  overflow-y: auto;
  max-height: 250px;
  background-color: ${({ disabled }) => disabled ? 'lightgrey' : null };
`;

const OptionWrapper = styled.div`
  margin-bottom: 3px;
  width: 90%;
  display: flex;
  flex-direction: row;
  color: ${props => props.selected ? 'white' : null};
  background-color: ${props => props.selected ? colorPicker(props.type) : null};
  ${props => props.disabled && !props.selected ? null :
    `&:hover {
      cursor: pointer;
      color: ${props => props.selected ? textColorPicker(props.type) : 'white'};
      background-color: ${hoverColorPicker(props.type)};
    }`
  }
`;

const HeaderText = styled.h3`
  font-weight: bold;
`;

const colorPicker = (type) => type === 'synonym' ? Theme.palette.secondary.main : Theme.palette.primary.main;
const hoverColorPicker = (type) => type === 'synonym' ? Theme.palette.secondary.light : Theme.palette.primary.light;
const textColorPicker = (type) => type === 'synonym' ? Theme.palette.secondary.contrastText : Theme.palette.primary.contrastText;

const Option = styled.div`
`;

const KeywordButton = ({ option, keywordType, keywordNumber, onClickHandler, color }) => (
  <IconButton size="large" onClick={() => onClickHandler({[keywordType]: option})}><Add /> {keywordNumber}</IconButton>
);


const List = ({ onKeywordClick, options, header, disabled, selectedWords, type }) => {
  // console.log('options:', options);
  const selectedKeys = Object.keys(selectedWords);
  const mergedOptions = Object.assign({}, options, _pick(selectedWords, (value, key) => value.type === type));
  selectedKeys.map(key => {
    if (mergedOptions[key]) mergedOptions.selected = true;
  });
  const optionsKeys = Object.keys(mergedOptions);
  return (
    <ListWrapper>
      <HeaderText>{header}</HeaderText>
      <OptionsWrapper disabled={disabled}>
      { optionsKeys.length
        ? optionsKeys.map(optionKey => {
          const option = mergedOptions[optionKey];
          const displayOption = option.word;
          const selected = selectedKeys.filter(key => selectedWords[key].word === option.word).length > 0;
          // console.log('option:', option);
          return (
            <OptionWrapper type={option.type} key={displayOption} onClick={() => onKeywordClick(option)} disabled={disabled} selected={selected}>
              {selected ? <RBC /> : <RBUC />}
              <Option >
                {displayOption}
              </Option>
            </OptionWrapper>

        )
      })
        : null}
      </OptionsWrapper>
    </ListWrapper>
  )
}

export default List;
