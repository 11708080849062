import React, { Component } from 'react'
import styled from 'styled-components'
import Theme from '../utils/theme'

export const Tip = styled.span`
  font-style: italic;
  font-size: 0.85rem;
  margin-left: 10px;
`

export const Step = styled.span`
  font-size: 2rem;
  width: 4rem;
  text-align: center;
  font-weight: bolder;
`

export const StepNumberWrapper = styled.div`
  display: flex;
  background-color: ${Theme.palette.secondary.main};
  border-radius: 200px;
  width: ${props => props.size || 4}rem;
  height: ${props => props.size || 4}rem;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-right: 20px;
`

export const StepNumber = ({ num, size }) => (
  <StepNumberWrapper size={size}>
    <Step>{num}</Step>
  </StepNumberWrapper>
)

export const KeyWordHeader = styled.h3`
  margin-top: ${props => (props.noMargin ? 0 : 0.5)}rem;
  margin-bottom: 0rem;
  display: flex;
  flex-direction: row;
  align-items: baseline;
`
