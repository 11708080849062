// Create our initial value...
const christmas = {
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        data: { topkey: 'top' },
        nodes: [
          {
            object: 'inline',
            type: 'string',
            data: { ukey: 'text' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text:
                      'While you deck the halls and bake Christmas cookies this season, sport a classic holiday t-shirt design featuring ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'synonym',
            data: { ukey: 'synonym1' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: ' synonym1 ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'string',
            data: { ukey: 'text' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: ', ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'synonym',
            data: { ukey: 'synonym2' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: ' synonym2 ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'string',
            data: { ukey: 'text' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: ' and ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'synonym',
            data: { ukey: 'synonym3' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: ' synonym3 ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'string',
            data: { ukey: 'text' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: ', and get into the holiday mood.',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
}

export default christmas
