import moment from 'moment'
import fb from '../config/constants'
import { getUser } from './user.js'

export function logout() {
  return fb.firebaseAuth().signOut()
}

export function login(email, pw) {
  return fb.firebaseAuth().signInWithEmailAndPassword(email, pw)
}

export function resetPassword(email) {
  return fb.firebaseAuth().sendPasswordResetEmail(email)
}

export function getCurrentUserToken() {
  return fb
    .firebaseAuth()
    .currentUser.getIdToken()
    .catch(err => console.error('err', err.message))
}

export function register(email, pw) {
  return fb
    .firebaseAuth()
    .createUserWithEmailAndPassword(email, pw)
    .then(metaUser => {
      // console.log('METAUSER:', metaUser)
      fb.firebaseAuth()
        .currentUser.sendEmailVerification()
        .then(() => {
          console.log('Verification Email Sent')
        })
        .catch(err => {
          console.error(err)
        })
      return saveUser(metaUser, metaUser.email)
    })
}

export function saveUser(metaUser, chosenName) {
  const updates = {}
  const user = metaUser
  // Get the user's real name from the auth provider, or from their register form.
  const displayName = user.displayName || chosenName

  // Fill in intial user object
  return fb.db
    .collection('users')
    .doc(user.uid)
    .set({
      email: user.email || user.providerData[0].email,
      displayName,
      uid: user.uid,
      avatarUrl:
        user.providerData[0].photoURL || `https://avatar.tobi.sh/${user.uid}`,
      providerId: user.providerData[0].providerId.replace('.com', ''),
      creationDate: moment.now(),
      lastUpdateDateTime: moment.now(),
      nextChargeDateTime: null,
      subSince: null,
      subscriptionEnds: "2019",
      subscriptionType: "TRIAL",
      accountType: 'PRO',
    })
    .then(() => user)
}

export function signInWithPopup(source) {
  const providers = {
    // google: new firebaseAuth.GoogleAuthProvider(),
    twitter: new fb.firebaseAuth.TwitterAuthProvider(),
    // facebook: new firebaseAuth.FacebookAuthProvider()
  }
  return fb
    .firebaseAuth()
    .signInWithPopup(providers[source])
    .then(result => {
      getUser(result.user.uid).then(data => {
        if (!data) {
          saveUser(result.user)
        }
      })
    })
}
