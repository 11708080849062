import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const config = {
  apiKey: process.env.FB_API_KEY,
  authDomain: process.env.FB_AUTH_DOMAIN,
  databaseURL: process.env.FB_DATABASE_URL,
  projectId: process.env.FB_PROJECT_ID,
  storageBucket: process.env.FB_STORAGE_BUCKET,
  messagingSenderId: process.env.FB_MESSAGING_SENDER_ID,
}

const settings = { timestampsInSnapshots: true }

class FirebaseInstance {
  constructor() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      firebase.initializeApp(config)
      this.firebaseAuth = firebase.auth
      this.storage = firebase.storage()
      this.db = firebase.firestore()
      this.db.settings(settings)
      this.db.enablePersistence().catch(function(err) {
        if (err.code == 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
          console.log('could not persist data due to multiple tabs')
        } else if (err.code == 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
          console.log('this browser does not support persistence')
        }
      })
      // Subsequent queries will use persistence, if it was enabled successfully
      this.storageRef = this.storage.ref()
      this.firebase = firebase
    }
  }
}

const newInstance = new FirebaseInstance()

export default newInstance

// if (typeof window !== 'undefined') {
// firebase.initializeApp(config);
// }
//
// export const db = firebase.firestore();
// db.settings(settings);
// export const firebaseAuth = firebase.auth;
// export const storage = firebase.storage();
// export const storageRef = storage.ref();
