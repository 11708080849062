import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Auth from '../containers/AppContainer';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Theme from '../utils/theme';
import { StaticQuery, graphql } from "gatsby";

import Header from '../components/header'
//import './index.css'

    //<Header siteTitle={data.site.siteMetadata.title} />

    const Layout = ({ children, ...rest }) => (
      <StaticQuery
        query={graphql`
          query siteDataQuery {
            site {
              siteMetadata {
                title,
                description,
                keywords,
              },
            }
          }
        `}
        render={data => (
          <MuiThemeProvider theme={Theme}>
            <Auth>
              {auth => (
                <div>
                  <Helmet
                    title={data.site.siteMetadata.title}
                    meta={[
                      { name: 'description', content: data.site.siteMetadata.description },
                      { name: 'keywords', content: data.site.siteMetadata.keywords },
                    ]}
                  />
                  <Header siteTitle={data.site.siteMetadata.title} auth={auth} />
                  <div
                    style={{
                      margin: '7rem auto 1rem',
                      maxWidth: '1540px',
                      padding: '1.45rem 1.0875rem 1.45rem',
                      paddingTop: 0,
                    }}
                    >
                      {children}
                    </div>
                  </div>
                )}
              </Auth>
            </MuiThemeProvider>
        )}
      />
    )

Layout.propTypes = {
  children: PropTypes.object,
}

export default Layout;
