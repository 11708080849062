import { workHardPlayHard as whph } from './premadeTemplates/workHardPlayHard'
import h from './premadeTemplates/hobby'
import p from './premadeTemplates/popSocket'
import p2 from './premadeTemplates/popSocket2'
import xmas from './premadeTemplates/christmas'
import Guid from './Guid'

export const workHardPlayHard = whph
export const hobby = h
export const christmas = xmas
export const popSocket = p
export const popSocket2 = p2
// Create our initial value...
export const initialValue = {
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        data: { topkey: 'top' },
        nodes: [
          {
            object: 'inline',
            type: 'string',
            data: { ukey: 'text' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: 'Whether you love ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'synonym',
            data: { ukey: `synonym1` },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: `synonym1`,
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'string',
            data: { ukey: 'text' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: ', ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'synonym',
            data: { ukey: 'synonym2' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: 'synonym2',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'string',
            data: { ukey: 'text' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: ' or ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'relatedWord',
            data: { ukey: 'relatedWord1' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: 'relatedWord1',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'string',
            data: { ukey: 'text' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text:
                      ' , this graphic t-shirt will be perfect for the next time you go ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'relatedWord',
            data: { ukey: 'relatedWord2' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: 'relatedWord2',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
}

// Create our initial value...
export const secondSentence = {
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        data: { topkey: 'top' },
        nodes: [
          {
            object: 'inline',
            type: 'string',
            data: { ukey: 'text' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: "It's ",
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'synonym',
            data: { ukey: `synonym1` },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: ' synonym1 ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'string',
            data: { ukey: 'text' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: ', ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'synonym',
            data: { ukey: 'synonym2' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: ' synonym2 ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'string',
            data: { ukey: 'text' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: ' and ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'synonym',
            data: { ukey: 'synonym3' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: ' synonym3 ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'string',
            data: { ukey: 'text' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text:
                      ' all day, everyday! This quirky graphic tee is sure to make a good impression on your friends and family. Wear it to the family party or night out!',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
}

export const customInitialValue = {
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        data: { topkey: 'top' },
        nodes: [],
      },
    ],
  },
}
