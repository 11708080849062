// Create our initial value...
const hobby = {
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        data: { topkey: 'top' },
        nodes: [
          {
            object: 'inline',
            type: 'string',
            data: { ukey: 'text' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: 'New to ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'synonym',
            data: { ukey: 'synonym1' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: ' synonym1 ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'string',
            data: { ukey: 'text' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: 'or old pro at ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'synonym',
            data: { ukey: 'synonym2' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: ' synonym2 ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'string',
            data: { ukey: 'text' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: ', this clever design is sure to make your ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'synonym',
            data: { ukey: 'synonym3' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text: ' synonym3 ',
                  },
                ],
              },
            ],
          },
          {
            object: 'inline',
            type: 'string',
            data: { ukey: 'text' },
            nodes: [
              {
                object: 'text',
                leaves: [
                  {
                    text:
                      ' friends smile. This is THE hobby to have and THE hobby t-shirt to wear.',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
}

export default hobby
