import React, { Component } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import Add from '@material-ui/icons/Add'
import CircularProgress from '@material-ui/core/CircularProgress'
import List from './List'
import Theme from '../../utils/theme'
import {
  StepNumber,
  Step,
  StepNumberWrapper,
  KeyWordHeader,
  Tip,
} from '../KeywordHeader'

const OptionsListWrapper = styled.div`
  padding: 1rem 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const ListLoader = styled.div`
  text-align: center;
  width: 100%;
`

const KeywordSearchForm = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const SearchMessage = () => {
  return (
    <ListLoader>
      Search for keywords to find great related words to build your product
      bullets
    </ListLoader>
  )
}
const ListWrapper = ({ children, fetching, defaultRender }) => (
  <OptionsListWrapper>
    {fetching ? (
      <ListLoader>
        <CircularProgress />
      </ListLoader>
    ) : defaultRender ? (
      <SearchMessage />
    ) : (
      children
    )}
  </OptionsListWrapper>
)

const Container = styled.div``

const KeyWordInput = styled.input`
  margin-left: 5rem;
  margin-right: 1rem;
  @media (max-width: 1000px) {
    margin: 1em 0;
    width: 100%;
  }
`
const KeywordSearchButton = styled(Button)`
  @media (max-width: 1000px) {
    width: 100%;
  }
`

const KeywordButton = ({
  option,
  keywordType,
  keywordNumber,
  onClickHandler,
  color,
}) => (
  <IconButton
    size="large"
    onClick={() => onClickHandler({ [keywordType]: option })}
  >
    <Add /> {keywordNumber}
  </IconButton>
)

const OptionsList = ({
  onKeywordClick,
  synonyms,
  relatedWords,
  onInputChange,
  inputVal,
  fetchWords,
  heading,
  relatedWordKeysAvail,
  synonymKeysAvail,
  selectedWords,
  fetching,
}) => {
  const synonymListLength = Object.keys(synonyms).length
  const relatedWordListLength = Object.keys(relatedWords).length
  return (
    <Container>
      <KeyWordHeader>
        {' '}
        <StepNumber num={1} /> ENTER A KEYWORD{' '}
        <Tip>
          {
            'Tip: Pick the most obvious keyword for your design. i.e. "baseball"'
          }
        </Tip>{' '}
      </KeyWordHeader>
      <KeywordSearchForm
        onSubmit={e => {
          e.preventDefault()
          fetchWords({ entry: inputVal })
        }}
      >
        <KeyWordInput
          onChange={onInputChange}
          placeholder="Enter a word or phrase"
          value={inputVal}
        />
        <KeywordSearchButton
          variant="contained"
          color="primary"
          onClick={() => fetchWords({ entry: inputVal })}
        >
          GET WORDS
        </KeywordSearchButton>
      </KeywordSearchForm>
      <KeyWordHeader>
        {' '}
        <StepNumber num={2} />{' '}
        {fetching ? 'fetching keywords...' : 'SELECT UP TO 3 FROM EACH LIST'}{' '}
      </KeyWordHeader>
      <ListWrapper
        fetching={fetching}
        defaultRender={synonymListLength === 0 && relatedWordListLength === 0}
      >
        {synonymListLength ? (
          <List
            options={synonyms}
            type="synonym"
            selectedWords={selectedWords}
            header="ALPHA LIST"
            onKeywordClick={onKeywordClick}
            disabled={synonymKeysAvail.length === 0}
          />
        ) : null}
        {relatedWordListLength ? (
          <List
            options={relatedWords}
            type="relatedWord"
            selectedWords={selectedWords}
            header="BETA LIST"
            onKeywordClick={onKeywordClick}
            disabled={relatedWordKeysAvail.length === 0}
          />
        ) : null}
      </ListWrapper>
    </Container>
  )
}

export default OptionsList
