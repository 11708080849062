import React from 'react'
import {
  EditorState,
  ContentState,
  convertFromRaw,
  convertFromHTML,
  CompositeDecorator,
  replaceData,
  convertToRaw,
} from 'draft-js'
import styled from 'styled-components'
import {
  getEntityStrategy,
  TokenSpan,
  rawContent,
} from '../utils/staticContent'
import { Editor } from 'slate-react'
import { Value } from 'slate'
import StickyInlines from 'slate-sticky-inlines'
import Theme from '../utils/theme'

const SentenceContainer = styled.div`
  padding: 1rem;
`

const plugins = [
  StickyInlines({
    allowedTypes: ['link'],
    bannedTypes: ['file'],
    canBeEmpty: true,
    hasStickyBoundaries: true,
    stickOnDelete: true,
  }),
]

// Define a React component renderer for our code blocks.
const Noun1 = styled.span`
  color: ${Theme.palette.secondary.main};
  font-weight: bold;
`

const Verb1 = styled.span`
  color: ${Theme.palette.primary.light};
  font-weight: bold;
`

const SwappableNode = ({ DisplayComponent, ...props }) => {
  return (
    <DisplayComponent {...props.attributes}>
      <span>{props.children}</span>
    </DisplayComponent>
  )
}

const schema = {
  document: {
    nodes: [
      {
        match: { type: 'paragraph' },
      },
    ],
  },
  blocks: {
    paragraph: {
      nodes: [
        {
          match: [{ object: 'text' }, { object: 'inline' }],
        },
      ],
    },
    inline: {
      nodes: [
        {
          match: [
            { object: 'text' },
            { type: 'string' },
            { type: 'relatedWord' },
            { type: 'synonym' },
          ],
        },
      ],
    },
  },
}

class MyEditor extends React.Component {
  constructor(props) {
    super(props)
    // const blocksFromHTML = convertFromHTML(props.initialContent);
    // const state = ContentState.createFromBlockArray(
    //   blocksFromHTML.contentBlocks,
    //   blocksFromHTML.entityMap
    // );
  }
  renderNode = props => {
    switch (props.node.type) {
      case 'synonym':
        return <SwappableNode {...props} DisplayComponent={Noun1} />
      case 'relatedWord':
        return <SwappableNode {...props} DisplayComponent={Verb1} />
    }
  }
  render() {
    return (
      <SentenceContainer>
        <Editor
          plugins={plugins}
          schema={schema}
          // id={this.props.id}
          // ref={this.props.innerRef}
          value={this.props.value}
          onChange={this.props.onChange}
          renderNode={this.renderNode}
        />
      </SentenceContainer>
    )
  }
}

export default MyEditor
