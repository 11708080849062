import React from 'react'
import Button from '@material-ui/core/Button'
import { redirectTo } from '@reach/router'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faRocket from '@fortawesome/fontawesome-free-solid/faRocket'
import faTshirt from '@fortawesome/fontawesome-free-solid/faTshirt'
// import faTwitter from '@fortawesome/fontawesome-free-solid/faTwitter'
import Box from '@material-ui/core/Paper'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import Theme from '../utils/theme'
//import logo from '../../logo.png';
import {
  register,
  login,
  signInWithPopup,
  resetPassword,
} from '../fireHelpers/auth'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'

const LoginPage = styled.div`
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
`

const LoginFormWrapper = styled.div`
  margin: 3rem auto;
  align: center;
  min-width: 350px;
`

const Mode = styled(Button)`
  width: 50%;
  display: inline-block;
  padding: 4px 12px;
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
  }
`

const ModeContainer = styled(Box)`
  margin-bottom: 12px;
`

function setErrorMsg(error) {
  console.error(error)
  if (typeof error === 'string') return { loginMessage: error }
  return { loginMessage: error.message }
}

export default class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      registerMode: true,
      loginMessage: null,
      redirectToReferrer: false,
    }
  }
  resetPassword = () => {
    resetPassword(this.email.value)
      .then(() =>
        this.setState(
          setErrorMsg(`Password reset email sent to ${this.email.value}.`)
        )
      )
      .catch(() => this.setState(setErrorMsg('Email address not found.')))
  }

  onRegister = e => {
    e.preventDefault()
    register(this.email.value, this.pw.value).catch(e =>
      this.setState(setErrorMsg(e))
    )
  }
  onLogin = e => {
    e.preventDefault()
    login(this.email.value, this.pw.value).then(result => console.log('LOGIN'))
  }
  // auth(email, pw, playerName) {
  render() {
    return (
      <MuiThemeProvider theme={Theme}>
        <LoginPage>
          <LoginFormWrapper>
            <Mode
              label="Login"
              variant={!this.state.registerMode ? 'contained' : 'outlined'}
              color={!this.state.registerMode ? 'secondary' : 'primary'}
              onClick={() => this.setState({ registerMode: false })}
            >
              <FontAwesomeIcon icon={faTshirt} /> LOGIN
            </Mode>
            <Mode
              label="Register"
              variant={this.state.registerMode ? 'contained' : 'outlined'}
              color={this.state.registerMode ? 'secondary' : 'primary'}
              onClick={() => this.setState({ registerMode: true })}
            >
              <FontAwesomeIcon icon={faRocket} /> REGISTER
            </Mode>
            {/* <Button
              label="Login with Twitter"
              fullWidth
              color={'secondary'}
              onClick={() => signInWithPopup('twitter')}
            >
              <FontAwesomeIcon icon={faTshirt} /> LOGIN WITH TWITTER
            </Button> */}
            {this.state.registerMode ? (
              <form onSubmit={this.onRegister}>
                <div className="form-group">
                  <TextField
                    label="Email"
                    className="form-control"
                    inputRef={email => (this.email = email)}
                    placeholder="Email"
                    fullWidth
                  />
                </div>
                <div className="form-group">
                  <TextField
                    label="Password"
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    inputRef={pw => (this.pw = pw)}
                    fullWidth
                  />
                </div>
                {this.state.loginMessage && (
                  <div className="alert alert-danger" role="alert">
                    <span
                      className="glyphicon glyphicon-exclamation-sign"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Error:</span>
                    &nbsp;
                    {this.state.loginMessage}{' '}
                    <a
                      href="#"
                      onClick={this.resetPassword}
                      className="alert-link"
                    >
                      Forgot Password?
                    </a>
                  </div>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color={'secondary'}
                  className="btn btn-primary"
                >
                  REGISTER
                </Button>
              </form>
            ) : (
              <form onSubmit={this.onLogin}>
                <div className="form-group">
                  <TextField
                    label="Email"
                    className="form-control"
                    inputRef={email => (this.email = email)}
                    placeholder="Email"
                    fullWidth
                  />
                </div>
                <div className="form-group">
                  <TextField
                    label="Password"
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    inputRef={pw => (this.pw = pw)}
                    fullWidth
                  />
                </div>
                {this.state.loginMessage && (
                  <div className="alert alert-danger" role="alert">
                    <span
                      className="glyphicon glyphicon-exclamation-sign"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Error:</span>
                    &nbsp;
                    {this.state.loginMessage}{' '}
                    <a
                      href="#"
                      onClick={this.resetPassword}
                      className="alert-link"
                    >
                      Forgot Password?
                    </a>
                  </div>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color={'secondary'}
                  className="btn btn-primary"
                >
                  LOGIN
                </Button>
              </form>
            )}
          </LoginFormWrapper>
        </LoginPage>
      </MuiThemeProvider>
    )
  }
}
